
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue, { VueConstructor } from 'vue'
import { ValidationObserver, extend } from 'vee-validate'
import Product from '../../../../store/models/Product'
import { InterestCalculTypeReadable } from '../../../../store/models/Product'
import Category from '../../../../store/models/Category'
import Organization from '../../../../store/models/Organization'
import Multiselect from 'vue-select'

interface ComponentWithValidator extends Vue {
  product: any
  manageFinancialFlow: string
  categorie: any
  productNumber: string
  typeFinance: any
  description: string
  fraisRetrais: string
  differeBeneficiaire: string
  durreDiffereBeneficiaireMin: number
  durreDiffereBeneficiaireMax: number
  durreDiffereSfdMin: number
  durreDiffereSfdMax: number
  differeSfd: string
  //durreDiffereSfd: string
  graceDelaySfdMin: number
  graceDelaySfdMax: number
  graceDelaySfd: string
  montMin: number
  montMax: number
  /* montMinSfd: number
  montMaxSfd: number */
  dureeMin: number
  dureeMax: number
  mtnFees: number
  moovFees: number
  sfdLoanMinDuration: number
  sfdLoanMaxDuration: number
  moisDiffereSfd: string
  periodeRemBenef: number
  periodeRemSfd: number
  beneficiaryInterestCalculMode: any
  sfdInterestCalculMode: string
  deferredMode: string
  interestRateBeneficiary: number
  interestRateSfd: number
  name: string
  organizationsChoices: any
  optionsBeneficiarie: Array<{}>
  optionsTypeFinance: Array<{}>
  actuel: number
  nbOfBeneficiaryPerGroup: Array<{ number: string; index: string }>
  organisations: Array<{}>
  valueSfd: []
  optionSfd: Array<{}>
  InterestCalculTypeReadable: any
  step1IsValid: boolean
  step2IsValid: boolean
  step3IsValid: boolean
  mtnDefaultOvaPhone: string
  mtnDefaultOvaUsername: string
  mtnDefaultOvaPwd: string
  mtnRefundOvaPhone: string
  mtnRefundOvaUsername: string
  mtnRefundOvaPwd: string
  moovDefaultOvaPhone: string
  moovDefaultOvaUsername: string
  moovDefaultOvaPwd: string
  moovRefundOvaPhone: string
  moovRefundOvaUsername: string
  moovRefundOvaPwd: string
  $refs: {
    validator4: InstanceType<typeof ValidationObserver>
    validator3: InstanceType<typeof ValidationObserver>
    validator1: InstanceType<typeof ValidationObserver>
    validator2: InstanceType<typeof ValidationObserver>
  }
  getBool: (value: string) => boolean
}

export default (Vue as VueConstructor<ComponentWithValidator>).extend({
  computed: {
    getActuel() {
      return this.actuel
    },
    productList() {
      return Product.all()
    },
    categories() {
      return Category.all()
    },
    organizations() {
      return Organization.query()
        .where('type', 'sfd')
        .orderBy((organization: any) => organization['name'].replace(/ /g, ''))
        .get()
    },
    verifyBeneficiaryMaxAmount() {
      return {
        numeric: true,
        minValue: this.montMin
      }
    },
    verifySfdInterest() {
      return {
        maxValue: this.interestRateBeneficiary
      }
    },
    /* verifySfdMaxAmount() {
      return {
        numeric: true,
        minValue: this.montMinSfd
      }
    }, */
    verifyOvaNumber() {
      return {
        numeric: true,
        length: 11
      }
    },
    verifySfdLoanMaxDuration() {
      return {
        numeric: true,
        minValue: this.sfdLoanMinDuration
      }
    },
    verifyBeneficiaryLoanMaxDuration() {
      return {
        required: true,
        numeric: true,
        minValue: this.dureeMin
      }
    },

    verifyDurreDiffereBeneficiaireMax() {
      return {
        numeric: true,
        minValue: this.durreDiffereBeneficiaireMin
      }
    },

    verifyDurreDiffereSfdMax() {
      return {
        numeric: true,
        minValue: this.durreDiffereSfdMin
      }
    },

    verifyGraceDelaySfdMax() {
      return {
        numeric: true,
        minValue: this.graceDelaySfdMin
      }
    }
  },

  watch: {},
  data() {
    return {
      validateActionLoading: false,
      showValidateAlert: false,
      showErrorCreation: false,
      step1IsValid: false,
      step2IsValid: false,
      step3IsValid: false,
      manageFinancialFlow: 'oui',
      categorie: '',
      productNumber: '',
      typeFinance: [],
      description: '',
      fraisRetrais: 'non',
      differeBeneficiaire: 'non',
      durreDiffereBeneficiaireMin: '',
      durreDiffereBeneficiaireMax: '',
      durreDiffereSfdMin: '',
      durreDiffereSfdMax: '',
      differeSfd: 'non',
      graceDelaySfdMin: '',
      graceDelaySfdMax: '',
      graceDelaySfd: 'non',
      //durreDiffereSfd: '',
      montMin: '',
      montMax: '',
      /* montMinSfd: '',
      montMaxSfd: '', */
      dureeMin: '',
      dureeMax: '',
      sfdLoanMinDuration: '',
      sfdLoanMaxDuration: '',
      mtnFees: '',
      moovFees: '',
      moisDiffereSfd: '',
      periodeRemBenef: '',
      periodeRemSfd: '',
      beneficiaryInterestCalculMode: null,
      sfdInterestCalculMode: '',
      deferredMode: '',
      interestRateBeneficiary: '',
      interestRateSfd: '',
      name: '',
      organizationsChoices: [],
      actuel: 1,
      nbOfBeneficiaryPerGroup: [],
      organisations: [],
      valueSfd: [],
      mtnDefaultOvaPhone: '',
      mtnDefaultOvaUsername: '',
      mtnDefaultOvaPwd: '',
      mtnRefundOvaPhone: '',
      mtnRefundOvaUsername: '',
      mtnRefundOvaPwd: '',
      moovDefaultOvaPhone: '',
      moovDefaultOvaUsername: '',
      moovDefaultOvaPwd: '',
      moovRefundOvaPhone: '',
      moovRefundOvaUsername: '',
      moovRefundOvaPwd: '',
      optionSfd: [
        {
          name: 'Tous',
          index: '1'
        },
        {
          name: 'sfs',
          index: '2'
        },
        {
          name: 'sf',
          index: '3'
        }
      ],
      Categories: [
        {
          name: 'bbbbb',
          index: '1'
        }
      ],
      optionsBeneficiarie: [
        {
          number: '01',
          index: '1'
        },
        {
          number: '02',
          index: '2'
        },
        {
          number: '03',
          index: '3'
        },
        {
          number: '04',
          index: '4'
        },
        {
          number: '05',
          index: '5'
        },
        {
          number: '06',
          index: '6'
        },
        {
          number: '07',
          index: '7'
        },
        {
          number: '08',
          index: '8'
        },
        {
          number: '09',
          index: '9'
        }
      ],
      optionsTypeFinance: [
        {
          type: 'externe',
          index: '1'
        },
        {
          type: 'interne',
          index: '2'
        },
        {
          type: 'hors',
          index: '3'
        }
      ],
      InterestCalculTypeReadable: InterestCalculTypeReadable
    }
  },

  components: {
    Multiselect
  },

  methods: {
    getBool(value: string): boolean {
      if (value == 'oui') return true
      return false
    },
    createProduct(): void {
      this.$refs.validator4.validate().then((isValid: boolean) => {
        const creditDetails: any = {
          beneficiaryMinAmount: this.montMin,
          beneficiaryMaxAmount: this.montMax,
          beneficiaryLoanMinDuration: this.dureeMin,
          beneficiaryLoanMaxDuration: this.dureeMax,
          sfdLoanMinDuration: this.sfdLoanMinDuration,
          sfdLoanMaxDuration: this.sfdLoanMaxDuration,
          beneficiaryInterestCalculMode: this.beneficiaryInterestCalculMode,
          sfdInterestCalculMode: this.sfdInterestCalculMode,
          nbOfBeneficiaryPerGroup: this.nbOfBeneficiaryPerGroup.map(
            (value: any) => parseInt(value.number)
          ),
          allowWithdrawFees: this.fraisRetrais == 'oui' ? true : false
        }

        if (creditDetails.allowWithdrawFees) {
          if (this.mtnFees > 0) creditDetails.mtnFees = this.mtnFees
          if (this.moovFees > 0) creditDetails.moovFees = this.moovFees
        }

        const reimbursementDetails: any = {
          allowDeferredPaymentForBeneficiary: this.getBool(
            this.differeBeneficiaire
          ),
          allowDeferredPaymentForSfd: this.getBool(this.differeSfd),
          allowGraceDelayForSfd: this.getBool(this.graceDelaySfd),
          beneficiaryPaymentPeriodicity: this.periodeRemBenef,
          sfdPaymentPeriodicity: this.periodeRemSfd,
          beneficiaryInterestRate: this.interestRateBeneficiary,
          sfdInterestRate: this.interestRateSfd
        }

        if (reimbursementDetails.allowDeferredPaymentForBeneficiary) {
          reimbursementDetails.beneficiaryDeferredPaymentMinDuration = this.durreDiffereBeneficiaireMin
          reimbursementDetails.beneficiaryDeferredPaymentMaxDuration = this.durreDiffereBeneficiaireMax
        }
        if (reimbursementDetails.allowDeferredPaymentForSfd) {
          reimbursementDetails.sfdDeferredPaymentMinDuration = this.durreDiffereSfdMin
          reimbursementDetails.sfdDeferredPaymentMaxDuration = this.durreDiffereSfdMax
        }
        if (reimbursementDetails.allowGraceDelayForSfd) {
          reimbursementDetails.sfdGraceDelayMinDuration = this.graceDelaySfdMin
          reimbursementDetails.sfdGraceDelayMaxDuration = this.graceDelaySfdMax
        }

        const trData: any = {
          name: this.name,
          productNumber: this.productNumber,
          description: this.description,
          manageFinancialFlow: this.getBool(this.manageFinancialFlow),
          financementType: this.typeFinance.map((value: any) => {
            return value.type
          }),
          sfdConcern: this.organizationsChoices.map((value: any) => {
            return value.id
          }),
          categoryId: this.categorie,
          creditDetails,
          reimbursementDetails
        }
        if (trData.manageFinancialFlow) {
          trData.defaultOvaCredentials = [
            {
              otm: 'mtn',
              phoneNumber: this.mtnDefaultOvaPhone,
              username: this.mtnDefaultOvaUsername,
              password: this.mtnDefaultOvaPwd
            },
            {
              otm: 'moov',
              phoneNumber: this.moovDefaultOvaPhone,
              username: this.moovDefaultOvaUsername,
              password: this.moovDefaultOvaPwd
            }
          ]
          trData.refundOvaCredentials = [
            {
              otm: 'mtn',
              phoneNumber: this.mtnRefundOvaPhone,
              username: this.mtnRefundOvaUsername,
              password: this.mtnRefundOvaPwd
            },
            {
              otm: 'moov',
              phoneNumber: this.moovRefundOvaPhone,
              username: this.moovRefundOvaUsername,
              password: this.moovRefundOvaPwd
            }
          ]
        }

        if (isValid) {
          if (this.showValidateAlert == false) {
            this.showValidateAlert = true
            return
          }
          this.validateActionLoading = true
          Product.api()
            .create(trData)
            .then(() => {
              this.$router.push({
                name: 'product',
                params: {
                  successAlertMessage: `Le produit ${this.name} a été crée avec succès`
                }
              })
            })
            .catch((err: any) => {
              this.validateActionLoading = false
              this.showValidateAlert = false
              this.showErrorCreation = true
              console.log('this is error', err.response.data)
            })
          //this.actuel = 3
        }
      })
    },
    gotoForth(): void {
      this.$refs.validator3.validate().then((isValid: boolean) => {
        if (isValid) {
          this.actuel = 4
          this.step3IsValid = true
        } else this.step3IsValid = false
      })
    },
    gotoThird(): void {
      this.$refs.validator2.validate().then((isValid: boolean) => {
        if (isValid) {
          this.actuel = 3
          this.step2IsValid = true
        } else this.step2IsValid = false
      })
    },
    gotoSecond(): void {
      this.$refs.validator1.validate().then((isValid: boolean) => {
        if (isValid) {
          this.actuel = 2
          this.step1IsValid = true
        } else {
          this.step1IsValid = false
        }
      })
    },
    toggle(index: any): void {
      if (index == 2 && this.step1IsValid) {
        this.actuel = index
      } else if (index == 3 && this.step1IsValid && this.step2IsValid) {
        this.actuel = index
      } else if (
        index == 4 &&
        this.step1IsValid &&
        this.step2IsValid &&
        this.step3IsValid
      )
        this.actuel = index
      else if (index == 1) this.actuel = 1
    },
    resetData() {
      this.$refs.validator1.reset()
      this.$router.go(-1)
    }
  },

  created(): void {
    Category.api().fetchAll()
    Product.api().fetchAll()
    Organization.api().fetch()
    extend('uniqueProductCode', {
      message: () => 'Ce numéro de produit est déjà utilisé',
      validate: value => {
        if (
          Product.query()
            .where('productNumber', value)
            .exists()
        )
          return false
        return true
      }
    })
    extend('uniqueProductName', {
      message: () => 'Ce nom est déjà utilisé',
      validate: value => {
        if (
          Product.query()
            .where('name', value)
            .exists()
        )
          return false
        return true
      }
    })
  }
})
